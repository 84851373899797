var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null ? _vm.$t("action.create") : _vm.$t("action.edit"),
      },
    },
    [
      _vm.id != null
        ? _c("div", { staticClass: "grid sm:flex mb-2" }, [
            _c(
              "div",
              {
                staticClass:
                  "w-full sm:w-10/12 break-all text-center sm:text-left flex items-center",
              },
              [
                _c("label", { staticClass: "mr-4 tour-publication-3" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("formulario-da-campanha")))]),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.shortUrl))]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "w-full sm:w-2/12 flex justify-center sm:justify-start ml-0 sm:ml-4 mt-2 sm:mt-0",
              },
              [
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.shortUrl,
                        expression: "shortUrl",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onSucceddCopy,
                        expression: "onSucceddCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onErrorCopy,
                        expression: "onErrorCopy",
                        arg: "error",
                      },
                    ],
                    staticClass: "footer-button",
                    attrs: { type: "filled", icon: "content_copy" },
                  },
                  [_vm._v(_vm._s(_vm.$t("copiar")))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:191",
                  expression: "'required|max:191'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("title"), name: "title" },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("title"),
                    expression: "errors.has('title')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("title")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("description")))]),
            _c("ComplexEditor", {
              attrs: {
                editor_data: _vm.description,
                placeholder: _vm.$t("digite-aqui"),
              },
              on: {
                "update:editor_data": function ($event) {
                  _vm.description = $event
                },
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("description"),
                    expression: "errors.has('description')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("description")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row mt-2" }, [
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
          [
            _c("select-suggestion", {
              ref: "select_questionnaire",
              class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
              attrs: {
                max: 20,
                column: "name,description",
                model: "ContentQuestionnaire",
                label: _vm.$t("Questionnaire"),
                appendClearOption: true,
                placeholderText: _vm.$t("digite-o-nome-do-questionario"),
              },
              on: { "selected-model": _vm.updateGrid },
              model: {
                value: _vm.questionnaire,
                callback: function ($$v) {
                  _vm.questionnaire = $$v
                },
                expression: "questionnaire",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("questionnaire_id"),
                    expression: "errors.has('questionnaire_id')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "pb-1 pt-1" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("emails-notificacao")))]),
              _c("emails-input", {
                key: _vm.leadNoticeMails,
                attrs: {
                  initTags: _vm.leadNoticeMails,
                  validation: [
                    {
                      classes: "valid-email",
                      rule: ({ text }) => !this.$utils.validation.email(text),
                    },
                  ],
                  sendButton: false,
                },
                on: { tagsChanged: _vm.tagsChanged },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("phone"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("phone"),
                  expression: "errors.has('phone')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(" " + _vm._s(_vm.errors.first("phone")) + " ")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-row mt-4" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("action.cancel")))]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "campaigns.create",
                    expression: "'campaigns.create'",
                  },
                ],
                staticClass: "float-right mr-2",
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.create },
              },
              [_vm._v(_vm._s(_vm.$t("action.save")))]
            ),
          ],
          1
        ),
      ]),
      _vm.id != null
        ? _c(
            "div",
            [
              _c("vs-divider", { staticClass: "mt-8" }),
              _c("label", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("leads-registrados"))),
              ]),
              _vm.campaignLeadService
                ? _c("Grid", {
                    ref: "grid",
                    attrs: {
                      hide_actions: true,
                      service: _vm.campaignLeadService,
                      route_name: "leads/grid",
                      fetchOnMounted: false,
                      column_formats: {
                        created_at: (val) => {
                          return _vm.$utils.format.date(val)
                        },
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }